const SET_NOTES = "playbook/jahresgespraech2025/SET_NOTES";
const SET_ASSET = "playbook/jahresgespraech2025/SET_ASSET";
const SET_GESCHAEFTSLAGE_NOTES =
  "playbook/jahresgespraech2025/SET_GESCHAEFTSLAGE_NOTES";
const SET_INFLUENCE_NOTES = "playbook/jahresgespraech2025/SET_INFLUENCE_NOTES";
const SET_TAG = "playbook/jahresgespraech2025/SET_TAG";
const SET_OBJECT_ASSET = "playbook/jahresgespraech2025/SET_OBJECT_ASSET";
const REMOVE_TAG = "playbook/jahresgespraech2025/REMOVE_TAG";
const REMOVE_TAG_BY_NAME = "playbook/jahresgespraech2025/REMOVE_TAG_BY_NAME";

export const initialState = {
  notes: {
    description: "Notizen für das Kapitel",
    value: "",
    type: "info",
    orderID: null,
  },
  geschaeftslageNotes: {
    description: "Geschäftslage Notiz",
    value: "",
    type: "info",
    orderID: null,
  },
  influenceNotes: {
    description: "Einfluss Politische Entwicklung, Energiekrise",
    value: "",
    type: "info",
    orderID: null,
  },
  geschaeftslageRating: {
    description: "Geschäftslage Bewertung [1 Sehr schlecht] [5 Sehr gut]",
    value: 3,
    type: "info",
    orderID: null,
  },
  auftragslageRating: {
    description: "Auftragslage Bewertung [1 Sehr schlecht] [5 Sehr gut]",
    value: 3,
    type: "info",
    orderID: null,
  },
  digitalOfferRating: {
    description: "Digitales Angebot Bewertung [1 Sehr schlecht] [5 Sehr gut]",
    value: 3,
    type: "info",
    orderID: null,
  },
  knxRequest: {
    description:
      "Nachfrage von KNX [1 Sehr schlecht] [5 Sehr gut]",
    value: 3,
    type: "info",
    orderID: null,
  },
  vereinbartTags: {
    description: "Unternehmensziele Vereinbart",
    value: [],
    type: "info",
    orderID: null,
  },
  umgesetztTags: {
    description: "Unternehmensziele Umgesetzt",
    value: [],
    type: "info",
    orderID: null,
  },
  assetMustertafel: {
    description: "Mustertafel",
    url: "https://www.busch-jaeger-excellence.de/163",
    value: false,
    type: "info",
    orderID: null,
  },
  assetAusstellung: {
    description: "Ausstellung",
    url: "https://www.busch-jaeger-excellence.de/230",
    value: false,
    type: "info",
    orderID: null,
  },
  assetMusterkoffer: {
    description: "Musterkoffer",
    url: "https://www.busch-jaeger-excellence.de/200",
    value: false,
    type: "info",
    orderID: null,
  },
  assetWelcome: {
    description: "Welcome",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare",
    value: false,
    type: "info",
    orderID: null,
  },
  assetWelcomeIP: {
    description: "Welcome IP",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare",
    value: false,
    type: "info",
    orderID: null,
  },
  assetKNX: {
    description: "KNX",
    url: "https://new.abb.com/service/de/training/abb-university/germany/seminare",
    value: false,
    type: "info",
    orderID: null,
  },
  assetAvailableBrochures: {
    description: "Prospektmaterial",
    url: "https://www.busch-jaeger-excellence.de/400",
    value: false,
    type: "info",
    orderID: null,
  },
  assetStromkompass: {
    description: "Stromkompass",
    url: "https://stromkompass.de/",
    value: false,
    type: "info",
    orderID: null,
  },
  assetTechnicTalk: {
    description: "Technik Talk",
    url: "https://stromkompass.de/techniktalk/",
    value: false,
    type: "info",
    orderID: null,
  },
  assetPodcast: {
    description: "Podcast",
    url: "https://stromkompass.de/blindleistung/",
    value: false,
    type: "info",
    orderID: null,
  },
  assetInstructionAccessExPortal: {
    description: "Einweisung/Zugang Ex-Portal",
    url: "https://www.busch-jaeger-excellence.de/",
    value: false,
    type: "info",
    orderID: null,
  },
  assetCatalog: {
    description: "Katalog",
    url: "https://www.busch-jaeger.de/media/download/Broschueren/de/1858_Katalog_DE_2023_24_29-09_final.pdf",
    value: false,
    type: "info",
    orderID: null,
  },
  assetSpecialistDealerEntry: {
    description: "Fachhändlereintrag",
    url: "https://www.busch-jaeger-excellence.de/specialized-dealer",
    value: false,
    type: "info",
    orderID: null,
  },
  assetNewsletterSubscription: {
    description: "Anmeldung Newsletter",
    url: "https://www.busch-jaeger.de/newsletter",
    value: false,
    type: "info",
    orderID: null,
  },
  assetExcellencePortal: {
    description: "Zugang Excellence Portal",
    url: "https://www.busch-jaeger-excellence.de/",
    value: false,
    type: "info",
    orderID: null,
  },
  assetProServicePortal: {
    description: "ProService Portal",
    url: "https://www.busch-jaeger.de/pro-service-portal",
    value: false,
    type: "info",
    orderID: null,
  },
  assetFreeAtHome: {
    description: "Busch-free@home®",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare",
    value: false,
    type: "info",
    orderID: null,
  },
  assetCatalogTraining: {
    description: "Katalogschulung",
    value: false,
    type: "info",
    orderID: null,
  },
  assetService: {
    description: "Service/Beraten",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare/auftakt-rundum-erfolgreich",
    value: false,
    type: "info",
    orderID: null,
  },
  assetAllroundSuccess: {
    description: "Rundum Erfolgreich",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare/auftakt-rundum-erfolgreich",
    value: false,
    type: "info",
    orderID: null,
  },
  assetTraineeAcademy: {
    description: "Azubi Akademie",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare/azubi-akademie-azubis-im-umgang-mit-kunden",
    value: false,
    type: "info",
    orderID: null,
  },
  assetEnergieverteiler: {
    description: "Energieverteiler",
    url: "https://www.striebelundjohn.com/service-und-downloads/seminare/seminare-2023",
    value: false,
    type: "info",
    orderID: null,
  },
  assetStrieplan: {
    description: "Strieplan",
    url: "https://www.striebelundjohn.com/service-und-downloads/seminare/strieplan-seminare",
    value: false,
    type: "info",
    orderID: null,
  },
  assetInstallerDevices: {
    description: "Installationsgeräte",
    url: "https://new.abb.com/service/de/training/abb-university/germany/seminare",
    value: false,
    type: "info",
    orderID: null,
  },
  assetPlanningSecurity: {
    description: "DIN18015 T1 Planungssicherheit",
    url: "https://new.abb.com/service/de/training/abb-university/germany/seminare",
    value: false,
    type: "info",
    orderID: null,
  },
  assetFachkraftRWM: {
    description: "Fachkraft RWM",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare/zertifizierung-zur-fachkraft-fuer-rauchwarnmelder-nach-din-14676",
    value: false,
    type: "info",
    orderID: null,
  },
  assetBarrierFreeLiving: {
    description: "Komfort in allen Lebenslagen - Barrierefreies Wohnen",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare/komfort-in-allen-lebenslagen-und-lebensabschnitten-barrierefreie-elektrotechnik",
    value: false,
    type: "info",
    orderID: null,
  },
  assetKonventionelleInstallation: {
    description: "Konventionelle Installation / Busch-flextronics",
    url: "https://www.busch-jaeger.de/busch-jaeger/termine/busch-jaeger-seminare",
    value: false,
    type: "info",
    orderID: null,
  },
  vereinbarungenNotes: {
    description: "Checkliste/Ausstattung/Schulung Notiz",
    value: "",
    type: "info",
    orderID: null,
  },
  vereinbartTags2025: {
    description: "Vereinbarungen",
    value: [],
    type: "info",
    orderID: null,
  },
  knxTags2025: {
    description: "KNX Vorschläge",
    value: [],
    type: "info",
    orderID: null,
  },
  projectTags: {
    description: "Projekte",
    value: [],
    type: "info",
    orderID: null,
  },
  assetZweckbauBis1M: {
    description: "Zweckbau bis 1 Mio. €",
    value: false,
    type: "info",
    orderID: null,
  },
  assetZweckbauAb1M: {
    description: "Zweckbau ab 1 Mio. €",
    value: false,
    type: "info",
    orderID: null,
  },
  assetWohnbauBis50WE: {
    description: "Wohnbau bis 50 WE",
    value: false,
    type: "info",
    orderID: null,
  },
  assetWohnbauAb50WE: {
    description: "Wohnbau ab 50 WE",
    value: false,
    type: "info",
    orderID: null,
  },
  assetABBCounterDistribution: {
    description: "Zählerschrank Hauptverteiler",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetABBSmallDistributor: {
    description: "Kleinverteiler",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetABBREGDevices: {
    description: "REG-Geräte FI/LS, FI, LS",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetKNXSensorBJE: {
    description: "KNX-Sensorik BJE",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetKNXActuators: {
    description: "KNX Aktorik",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetOvervoltageProtection: {
    description: "Überspannungsschutz",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetSSM: {
    description: "Schalter- und Steckdosen Material",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetGuardian: {
    description: "Bewegungsmelder",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetPresenceDetector: {
    description: "Präsenzmelder",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetSHSFreeAtHome: {
    description: "Smart Home Systeme",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetElectronic: {
    description: "Elektronik (Dimmer, Timer, RTR)",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetAPIP44Ocean: {
    description: "Schaltermaterial AP+UP Wassergeschützt",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetDoorCommunication: {
    description: "Türkommunikation",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  assetElectromobility: {
    description: "Elektromobilität",
    value: {
      perc: 0,
      sc: null,
      r: null,
    },
    type: "info",
    orderID: null,
  },
  bjeVergleichNotes: {
    description: "Notizen",
    value: "",
    type: "info",
    orderID: null,
  },
  abbVergleichNotes: {
    description: "Notizen",
    value: "",
    type: "info",
    orderID: null,
  },
  projectRadius: {
    description: "Projekt-Umkreis",
    value: "",
    type: "info",
    orderID: null,
  },
  projectBrandUsage: {
    description: "Marken-Einsatz",
    value: "",
    type: "info",
    orderID: null,
  },
  projectNoBrandUsage: {
    description: "Kein Marken-Einsatz",
    value: "",
    type: "info",
    orderID: null,
  },
  projectCoops: {
    description: "Kooperationen",
    value: "",
    type: "info",
    orderID: null,
  },
  projectPlaner: {
    description: "Planer",
    value: "",
    type: "info",
    orderID: null,
  },
  projectArchitects: {
    description: "Architekten",
    value: "",
    type: "info",
    orderID: null,
  },
};

const jahresgespraech2025 = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: {
          ...state.notes,
          value: action.notes,
        },
      };
    }
    case SET_GESCHAEFTSLAGE_NOTES: {
      return {
        ...state,
        geschaeftslageNotes: {
          ...state.geschaeftslageNotes,
          value: action.geschaeftslageNotes,
        },
      };
    }
    case SET_INFLUENCE_NOTES: {
      return {
        ...state,
        influenceNotes: {
          ...state.influenceNotes,
          value: action.influenceNotes,
        },
      };
    }
    case SET_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: action.value,
        },
      };
    }
    case SET_TAG: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: [...state[action.id].value, action.value],
        },
      };
    }
    case REMOVE_TAG: {
      const valueTag = action.value;
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: state[action.id].value.filter((v) => v !== valueTag),
        },
      };
    }
    case REMOVE_TAG_BY_NAME: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: state[action.id].value.filter((v) => v.name !== action.name),
        },
      };
    }
    case SET_OBJECT_ASSET: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          value: {
            ...state[action.id].value,
            [action.attr]: action.value,
          },
        },
      };
    }
    default:
      return state;
  }
};

//actions
export const setJG25Notes = (notes) => ({
  type: SET_NOTES,
  notes,
});

export const setAsset = (id, value) => ({
  type: SET_ASSET,
  id,
  value,
});

export const setGeschaeftslageNotes = (geschaeftslageNotes) => ({
  type: SET_GESCHAEFTSLAGE_NOTES,
  geschaeftslageNotes,
});

export const setInfluenceNotes = (influenceNotes) => ({
  type: SET_INFLUENCE_NOTES,
  influenceNotes,
});

export const setTag = (id, value) => ({
  type: SET_TAG,
  id,
  value,
});

export const delTag = (id, value) => ({
  type: REMOVE_TAG,
  id,
  value,
});

export const delTagByName = (id, name) => ({
  type: REMOVE_TAG_BY_NAME,
  id,
  name,
});

export const setObjectAsset = (id, attr, value) => ({
  type: SET_OBJECT_ASSET,
  id,
  attr,
  value,
});

const get = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

//selector
export const getJahresgespraech2025Data = (state) => state.jahresgespraech2025 || {};

export const getNoteById = (state, noteID) => state.jahresgespraech2025[noteID];

export const getAssetByID = (state, id) => get(state.jahresgespraech2025, id);

export default jahresgespraech2025;
