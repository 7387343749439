/* eslint-disable */
import { h, Component } from "preact";
import Checkicon from "../../../components/Beratung/Result/checkicon.js";
import { getSetupData } from "../../../redux/modules/setupReducer";
import { connect, useSelector } from "react-redux";
import { route } from "preact-router";
import TextField from "@material-ui/core/TextField";
import QRCode from "qrcode.react";
import Link from "@material-ui/core/Link";
import { getSettingsData } from "../../../redux/modules/settingsReducer";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import {
  setActiveChapters,
  setNextAppointment,
  getConsultingData,
  setNextAppointmentPlace,
  setConsultingHasEnded,
  setConsultingTimeTrackingStatus,
} from "../../../redux/modules/consultingReducer";
import {
  getFreeAtHomeData,
  initialState as FreeAtHomeInitialState,
} from "../../../redux/modules/chapterFreeAtHome";
import {
  getMissionToZeroData,
  initialState as MissionToZeroInitialState,
} from "../../../redux/modules/chapterMissionToZero";
import {
  getWelcomeData,
  initialState as WelcomeInitialState,
} from "../../../redux/modules/chapterWelcome";
import {
  getWelcomeIPData,
  initialState as WelcomeIPInitialState,
} from "../../../redux/modules/chapterWelcomeIP";
import {
  getKnxData,
  initialState as KnxInitialState,
} from "../../../redux/modules/chapterKnx";
import {
  getComfortLineData,
  initialState as ComfortLineInitialState,
} from "../../../redux/modules/chapterComfortLine";
import {
  getInstallationsGeraeteData,
  initialState as InstallationsGeraeteInitialState,
} from "../../../redux/modules/chapterInstallationsgeraete";
import {
  getBeruehrungslosesSchaltenData,
  initialState as BeruehrungslosesSchaltenInitialState,
} from "../../../redux/modules/chapterBeruehrungslosesSchalten";
import {
  getCwaData,
  initialState as CwaInitialState,
} from "../../../redux/modules/chapterCwa";
import {
  getJahresgespraech2021Data,
  initialState as Jahresgespraech2021InitialState,
} from "../../../redux/modules/chapterJahresgespraech2021";
import * as R from "ramda";
import { resetConsulting } from "../../../redux/modules/reducers";
import { toast } from "react-toastify";
import { getCategoriesData } from "../../../redux/modules/categoriesReducer.js";
import { Offline, Online } from "react-detect-offline";
import { isLoggedIn } from "../../../redux/modules/userReducer.js";
import { validate as uuidValidate } from "uuid";
import { version as uuidVersion } from "uuid";
import APIService from "../../../api/index";
import {
  getJahresgespraech2022Data,
  initialState as Jahresgespraech2022InitialState,
} from "../../../redux/modules/chapterJahresgespraech2022.js";
import { getServiceAndToolsData } from "../../../redux/modules/chapterServiceAndTools.js";
import {
  getBuschflexData,
  initialState as BuschflexInitialState,
} from "../../../redux/modules/chapterBuschFlex";
import {
  getUK600AK600Data,
  initialState as Uk600AK600InitialState,
} from "../../../redux/modules/chapterUk600AK600";
import {
  getAbbSmarterMobilityData,
  initialState as AbbSmarterMobilityInitialState,
} from "../../../redux/modules/chapterAbbSmarterMobility";
import {
  getLbInnovationsData,
  initialState as LbInnovationsInitialState,
} from "../../../redux/modules/chapterLBInnovations";
import {
  getEnergieMonitoringData,
  initialState as EnergieMonitoringInitialState,
} from "../../../redux/modules/chapterEnergiemontoring";

import {
  getJahresgespraech2023Data,
  initialState as Jahresgespraech2023InitialState,
} from "../../../redux/modules/chapterJahresgespraech2023.js";
import {
  getModerneElektroinstallationData,
  initialState as ModerneElektroinstallationInitalState,
} from "../../../redux/modules/chapterModerneElektroinstallation";
import {
  getUSVData,
  initialState as USVInitialState,
} from "../../../redux/modules/chapterUSV.js";
import ButtonStack from "../../ButtonStack/index.js";
import {
  getJahresgespraech2024Data,
  initialState as Jahresgespraech2024InitialState,
} from "../../../redux/modules/chapterJahresgespraech2024.js";
import {
  getFlexLineData,
  initialState as FlexLineInitalState,
} from "../../../redux/modules/chapterFlexLine.js";
import {
  getNeuheiten2024Data,
  initialState as Neuheiten2024InitialState,
} from "../../../redux/modules/chapterNeuheiten2024.js";
import {
  getJahresgespraech2025Data,
  initialState as Jahresgespraech2025InitialState,
} from "../../../redux/modules/chapterJahresgespraech2025.js";

const optionsDate = { month: "long", day: "numeric" };
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  setupPage: {
    padding: theme.spacing(4),
    margin: theme.spacing(0),
    // height: '550px'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  paper: {
    margin: "auto",
  },
  paperElements: {
    margin: "0 auto",
    width: "100%",
    height: "100%",
  },
  cardImg: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "25px",
    justifyContent: "center",
  },
  buttonNext: {
    textAlign: "right",
  },
  endConsultation: {
    textAlign: "center",
  },
  paperStepHeight: {
    // paddingTop: '25px',
    height: "265px",
  },
  sendData: {
    display: "flex !important",
    margin: "0 auto",
    width: "max-content",
  },
  buttonS: {
    "&::before": {
      content: "none !important",
    },
  },
  lastStep: {
    background: "none",
    boxShadow: "none",
  },
  instructions: {
    color: "#000000",
    fontWeight: "bold",
  },
  centerContent: {
    textAlign: "center",
  },
}));

class ResultPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      resultUrl: "",
      step: 0,
      consultEndTime: null,
      emailLink: "",
    };
    this.sendData = this.sendData.bind(this);
    this.getURL = this.getURL.bind(this);
    this.getSteps = this.getSteps.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.setChapterData = this.setChapterData.bind(this);
    this.shareDialog = this.shareDialog.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.uuidValidateV4 = this.uuidValidateV4.bind(this);
  }

  componentDidMount() {
    let missiontozerochk = false;
    let buschfreeathomechk = false;
    let buschwelcomechk = false;
    let buschwelcomeipchk = false;
    let knxchk = false;
    let comfortlinechk = false;
    let installationsgeraetechk = false;
    let beruehrungslosesschaltenchk = false;
    let cwachk = false;
    let jahresgespraech_2021chk = false;
    let jahresgespraech_2022chk = false;
    let architektenchk = false;
    let serviceandtoolschk = false;
    let buschflexchk = false;
    let uk600ak600chk = false;
    let cylonchk = false;
    let smarterGallerychk = false;
    let abbSmarterMobilitychk = false;
    let lbInnovationschk = false;
    let energieMonitoringchk = false;
    let jahresgespraech_2023chk = false;
    let moderneElektroinstallationchk = false;
    let usvchk = false;
    let jahresgespraech_2024chk = false;
    let flexlinechk = false;
    let neuheiten2024chk = false;

    let chapters;

    if (!R.equals(FreeAtHomeInitialState, this.props.data.freeathome)) {
      buschfreeathomechk = true;
    }

    if (!R.equals(MissionToZeroInitialState, this.props.data.missiontozero)) {
      missiontozerochk = true;
    }

    if (!R.equals(WelcomeInitialState, this.props.data.welcome)) {
      buschwelcomechk = true;
    }

    if (!R.equals(WelcomeIPInitialState, this.props.data.welcomeip)) {
      buschwelcomeipchk = true;
    }

    if (!R.equals(KnxInitialState, this.props.data.knx)) {
      knxchk = true;
    }

    if (!R.equals(ComfortLineInitialState, this.props.data.comfortline)) {
      comfortlinechk = true;
    }

    if (
      !R.equals(
        InstallationsGeraeteInitialState,
        this.props.data.installationsgeraete
      )
    ) {
      installationsgeraetechk = true;
    }

    if (
      !R.equals(
        BeruehrungslosesSchaltenInitialState,
        this.props.data.beruehrungslosesschalten
      )
    ) {
      beruehrungslosesschaltenchk = true;
    }

    if (!R.equals(CwaInitialState, this.props.data.cwa)) {
      cwachk = true;
    }

    if (
      !R.equals(
        Jahresgespraech2021InitialState,
        this.props.data.jahresgespraech_2021
      )
    ) {
      jahresgespraech_2021chk = true;
    }

    if (
      !R.equals(
        Jahresgespraech2022InitialState,
        this.props.data.jahresgespraech_2022
      )
    ) {
      jahresgespraech_2022chk = true;
    }

    if (!R.equals(BuschflexInitialState, this.props.data.buschflex)) {
      buschflexchk = true;
    }

    if (!R.equals(Uk600AK600InitialState, this.props.data.uk600ak600)) {
      uk600ak600chk = true;
    }

    if (
      !R.equals(
        AbbSmarterMobilityInitialState,
        this.props.data.abbSmarterMobility
      )
    ) {
      abbSmarterMobilitychk = true;
    }

    if (!R.equals(LbInnovationsInitialState, this.props.data.lbInnovations)) {
      lbInnovationschk = true;
    }

    if (
      !R.equals(
        EnergieMonitoringInitialState,
        this.props.data.energieMonitoring
      )
    ) {
      energieMonitoringchk = true;
    }

    if (
      !R.equals(
        Jahresgespraech2023InitialState,
        this.props.data.jahresgespraech_2023
      )
    ) {
      jahresgespraech_2023chk = true;
    }

    if (
      !R.equals(
        ModerneElektroinstallationInitalState,
        this.props.data.moderneElektroinstallation
      )
    ) {
      moderneElektroinstallationchk = true;
    }

    if (!R.equals(USVInitialState, this.props.data.usv)) {
      usvchk = true;
    }

    if (
      !R.equals(
        Jahresgespraech2024InitialState,
        this.props.data.jahresgespraech_2024
      )
    ) {
      jahresgespraech_2024chk = true;
    }

    if (!R.equals(FlexLineInitalState, this.props.data.flexline)) {
      flexlinechk = true;
    }

    if (!R.equals(Neuheiten2024InitialState, this.props.data.neuheiten2024)) {
      neuheiten2024chk = true;
    }

    this.setState((prevState) => {
      chapters = Object.assign({}, prevState.chapters);
      chapters.missiontozero = missiontozerochk;
      chapters.freeathome = buschfreeathomechk;
      chapters.welcome = buschwelcomechk;
      chapters.welcomeip = buschwelcomeipchk;
      chapters.knx = knxchk;
      chapters.comfortline = comfortlinechk;
      chapters.installationsgeraete = installationsgeraetechk;
      chapters.beruehrungslosesschalten = beruehrungslosesschaltenchk;
      chapters.cwa = cwachk;
      chapters.jahresgespraech_2021 = jahresgespraech_2021chk;
      chapters.jahresgespraech_2022 = jahresgespraech_2022chk;
      chapters.architekten = architektenchk;
      chapters.serviceandtools = serviceandtoolschk;
      chapters.buschflex = buschflexchk;
      chapters.uk600ak600 = uk600ak600chk;
      chapters.cylon = cylonchk;
      chapters.smarterGallery = smarterGallerychk;
      chapters.abbSmarterMobility = abbSmarterMobilitychk;
      chapters.lbInnovations = lbInnovationschk;
      chapters.energieMonitoring = energieMonitoringchk;
      chapters.jahresgespraech_2023 = jahresgespraech_2023chk;
      chapters.moderneElektroinstallation = moderneElektroinstallationchk;
      chapters.usv = usvchk;
      chapters.jahresgespraech_2024 = jahresgespraech_2024chk;
      chapters.flexline = flexlinechk;
      chapters.neuheiten2024 = neuheiten2024chk;
      return { chapters };
    });
    this.props.setActiveChapters(chapters);
  }

  getDiffTime() {
    if (this.props.consultingStarted !== null) {
      let diffMs = this.state.consultEndTime - this.props.consultingStarted;
      let diffDays = Math.floor(diffMs / 86400000); // days
      let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      alert(`${diffDays} days, ${diffHrs} hours, ${diffMins} minutes)`);
    }
  }

  uuidValidateV4(uuid) {
    return uuidValidate(uuid) && uuidVersion(uuid) === 4;
  }

  shareDialog() {
    const shareData = {
      title: "Busch-eDialog",
      text: "",
      url: this.state.resultUrl,
    };
    try {
      navigator.share(shareData);
    } catch (err) {}
  }

  getURL(id) {
    return process.env.PREACT_APP_PLAYBOOKMICROSITE + id;
  }

  getSteps() {
    return [
      `Themen am ${new Date(
        this.props.data.setup.selectedDate
      ).toLocaleDateString("de-DE", optionsDate)}`,
      "Nächster Termin",
      "Abschluss",
    ];
  }

  handleNext() {
    this.setState({
      step: this.state.step + 1,
    });
  }

  handleBack() {
    this.setState({
      step: this.state.step - 1,
    });
  }

  handleReset(e) {
    this.props.resetConsulting();
    this.props.handleDialogClose(e);
    route("/", true);
  }

  handleDateChange(v) {
    this.props.setNextAppointment(v);
  }

  handleFieldChange(e) {
    e.preventDefault();
    this.props.setSetupField({
      id: e.currentTarget.id,
      value: e.currentTarget.value,
    });
  }

  setChapterData =
    (name, check = false) =>
    (event) => {
      let chapters = Object.assign(
        {},
        this.props.data.consulting.activeChapters
      );
      chapters[name] = check ? check : event.target.checked;
      this.props.setActiveChapters(chapters);
    };

  sendData() {
    let rMail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const finishTime = new Date().toISOString();
    let that = this;
    let data = R.clone(this.props.data);

    if (!data.settings.email.match(rMail)) {
      toast.error("Keine gültige ADM E-Mail hinterlegt.");
      return;
    }

    if (!data.setup.email.match(rMail)) {
      toast.error("Keine gültige Kunden E-Mail hinterlegt.");
      return;
    }

    try {
      if (data.settings.region.name != "") {
        data.settings.region = data.settings.region.name;
      } else {
        data.settings.region = "";
      }
    } catch (e) {
      console.warn("Region issue!");
      data.settings.region = "";
    }

    if (!this.uuidValidateV4(data.consulting.internalID)) {
      data.consulting.internalID = null;
    }

    if (data.settings.email.match(rMail) && data.setup.email.match(rMail)) {
      data.consulting.consultingEnded = finishTime;

      APIService.post(
        "api/consultation",
        JSON.stringify(data),
        "text/plain",
        (status, data) => {
          if (status === 200) {
            this.setState(
              {
                status: 2,
                resultUrl: this.getURL(data),
              },
              () => {
                this.setEmail();
                this.props.finishConsulting();
                toast.info("Beratung beendet.");
              }
            );
          }
        }
      );

      this.setState({
        status: 1,
        step: this.state.step + 1,
      });
    }
  }

  setEmail() {
    const {
      selectedDate,
      salutation,
      lastname: customerLastname,
      email,
    } = this.props.data.setup;
    const {
      firstname: admFirstname,
      lastname: admLastname,
      phonenr: AdmPhonenr,
      faxnr: admFaxnr,
      streetandnr: admStreetandnr,
      zipcode: admZipcode,
      place: admPlace,
      company: admCompany,
    } = this.props.data.settings;
    const optionsDate = { weekday: "long", month: "long", day: "numeric" };
    const breakLine = "\r\n";

    const mailTopic = `Busch-eDialog | Unser Gespräch am ${new Date(
      selectedDate
    ).toLocaleDateString("de-DE", optionsDate)}`;

    const emailHeader = `${
      salutation == "Frau" ? `Guten Tag Frau ` : `Guten Tag Herr `
    }${customerLastname},${breakLine}${breakLine}`;
    const emailBody = `die Inhalte unseres Gesprächs können Sie sich noch einmal hier ansehen:${breakLine}${breakLine}`;
    const emailResultMessage = `Busch-eDialog: ${this.state.resultUrl}${breakLine}${breakLine}`;
    const emailPrivacyUrl = `Unsere Hinweise zum Datenschutz finden Sie hier: https://new.abb.com/privacy-notice/de/customer/germany${breakLine}${breakLine}`;
    const emailFooter = `Mit freundlichen Grüßen${breakLine}${breakLine}${admFirstname} ${admLastname}${breakLine}${breakLine}Telefon ${AdmPhonenr}${breakLine}Telefax ${admFaxnr}${breakLine}${breakLine}${admCompany}${breakLine}${admStreetandnr}${breakLine}${admZipcode} ${admPlace}${breakLine}${breakLine}`;

    const mailBody = `${emailHeader}${emailBody}${emailResultMessage}${emailPrivacyUrl}${emailFooter}`;
    let link = `${
      `mailto:${email}` + "?subject="
    }${mailTopic}&body=${encodeURIComponent(mailBody)}`;

    this.setState({
      emailLink: link,
    });
  }

  render() {
    const { status, resultUrl, step, emailLink } = this.state;
    const {
      nextAppointment,
      nextAppointmentPlace,
      data,
      categories,
      isLoggedin,
    } = this.props;
    const {
      missiontozero,
      freeathome,
      welcome,
      welcomeip,
      knx,
      comfortline,
      installationsgeraete,
      beruehrungslosesschalten,
      cwa,
      jahresgespraech_2021,
      jahresgespraech_2022,
      architekten,
      serviceandtools,
      buschflex,
      uk600ak600,
      cylon,
      smarterGallery,
      abbSmarterMobility,
      lbInnovations,
      energieMonitoring,
      jahresgespraech_2023,
      moderneElektroinstallation,
      usv,
      jahresgespraech_2024,
      flexline,
      neuheiten2024,
    } = data.consulting.activeChapters;

    const classes = useStyles();
    const steps = this.getSteps();

    if (this.state.step === 2) {
      if (!navigator.onLine) {
        toast.error(
          "Keine Interverbindung.\nDie Beratung kann beendet werden, wenn Sie online sind."
        );
      }
    }

    return (
      <>
        {step !== steps.length && (
          <Stepper activeStep={step}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        {step === steps.length ? (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box marginBottom={3}>
              <Typography
                align="center"
                variant="h3"
                style={{ fontFamily: "Averta-Bold" }}
              >
                Vielen Dank!
              </Typography>
              <Typography align="center" variant="h5">
                Beratung erfolgreich beendet.
              </Typography>
            </Box>
            <Paper elevation={3} style={{ width: "fit-content" }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={3}
              >
                <Link
                  style={{ height: "128px", width: "128px" }}
                  href={resultUrl}
                  target="_blank"
                >
                  <QRCode height={128} width={128} value={resultUrl} />
                </Link>
                <Box>
                  <Box marginLeft={2}>
                    <Typography
                      variant="h5"
                      component="h2"
                      className={`text-center`}
                    >
                      Busch-eDialog
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={`text-center`}
                    >
                      Digitale Zusammenfassung des Gesprächs
                    </Typography>
                  </Box>
                  <Box marginLeft={1}>
                    <ButtonStack>
                      <Button
                        href={emailLink}
                        target="_blank"
                        variant="contained"
                        color="primary"
                      >
                        Per E-Mail versenden
                      </Button>
                      <Button
                        onClick={this.shareDialog}
                        onTouchStart={this.shareDialog}
                        variant="outlined"
                        color="primary"
                      >
                        Teilen
                      </Button>
                      <Button
                        href={resultUrl}
                        target="_blank"
                        variant="outlined"
                        color="primary"
                      >
                        Öffnen
                      </Button>
                    </ButtonStack>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            <div>
              {step === 0 && (
                <div>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                    fullWidth
                  >
                    <FormGroup>
                      <Grid container spacing={2} xs={12}>
                        {categories.missiontozero && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={missiontozero}
                                  onChange={this.setChapterData(
                                    "missiontozero"
                                  )}
                                  value="missiontozero"
                                />
                              }
                              label="Mission to Zero"
                            />
                          </Grid>
                        )}
                        {categories.freeathome && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={freeathome}
                                  onChange={this.setChapterData("freeathome")}
                                  value="freeathome"
                                />
                              }
                              label="Busch-free@home®"
                            />
                          </Grid>
                        )}
                        {categories.welcome && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={welcome}
                                  onChange={this.setChapterData("welcome")}
                                  value="welcome"
                                />
                              }
                              label="Busch-Welcome®"
                            />
                          </Grid>
                        )}
                        {categories.welcomeip && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={welcomeip}
                                  onChange={this.setChapterData("welcomeip")}
                                  value="welcomeip"
                                />
                              }
                              label="Busch-Welcome® IP"
                            />
                          </Grid>
                        )}
                        {categories.knx && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={knx}
                                  onChange={this.setChapterData("knx")}
                                  value="knx"
                                />
                              }
                              label="ABB i-bus® KNX"
                            />
                          </Grid>
                        )}
                        {categories.comfortline && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={comfortline}
                                  onChange={this.setChapterData("comfortline")}
                                  value="comfortline"
                                />
                              }
                              label="ComfortLine"
                            />
                          </Grid>
                        )}
                        {categories.installationsgeraete && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={installationsgeraete}
                                  onChange={this.setChapterData(
                                    "installationsgeraete"
                                  )}
                                  value="installationsgeraete"
                                />
                              }
                              label="Installationsgeräte"
                            />
                          </Grid>
                        )}
                        {categories.beruehrungslosesschalten && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={beruehrungslosesschalten}
                                  onChange={this.setChapterData(
                                    "beruehrungslosesschalten"
                                  )}
                                  value="beruehrungslosesschalten"
                                />
                              }
                              label="Berührungsloses Schalten"
                            />
                          </Grid>
                        )}
                        {categories.cwa && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={cwa}
                                  onChange={this.setChapterData("cwa")}
                                  value="cwa"
                                />
                              }
                              label="Lichtschalter"
                            />
                          </Grid>
                        )}
                        {isLoggedin && categories.jahresgespraech_2021 && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={jahresgespraech_2021}
                                  onChange={this.setChapterData(
                                    "jahresgespraech_2021"
                                  )}
                                  value="jahresgespraech_2021"
                                />
                              }
                              label="Jahresgespräch 2021"
                            />
                          </Grid>
                        )}
                        {isLoggedin && categories.jahresgespraech_2022 && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={jahresgespraech_2022}
                                  onChange={this.setChapterData(
                                    "jahresgespraech_2022"
                                  )}
                                  value="jahresgespraech_2022"
                                />
                              }
                              label="Jahresgespräch 2022"
                            />
                          </Grid>
                        )}
                        {categories.architekten && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={architekten}
                                  onChange={this.setChapterData("architekten")}
                                  value="architekten"
                                />
                              }
                              label="Architekten"
                            />
                          </Grid>
                        )}
                        {categories.serviceandtools && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={serviceandtools}
                                  onChange={this.setChapterData(
                                    "serviceandtools"
                                  )}
                                  value="serviceandtools"
                                />
                              }
                              label="Service & Tools"
                            />
                          </Grid>
                        )}
                        {categories.buschflex && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={buschflex}
                                  onChange={this.setChapterData("buschflex")}
                                  value="buschflex"
                                />
                              }
                              label="Busch-flexTronics®"
                            />
                          </Grid>
                        )}
                        {categories.uk600ak600 && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={uk600ak600}
                                  onChange={this.setChapterData("uk600ak600")}
                                  value="uk600ak600"
                                />
                              }
                              label="UK600 + AK600"
                            />
                          </Grid>
                        )}
                        {categories.cylon && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={cylon}
                                  onChange={this.setChapterData("cylon")}
                                  value="cylon"
                                />
                              }
                              label="ABB Cylon®"
                            />
                          </Grid>
                        )}
                        {categories.smarterGallery && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={smarterGallery}
                                  onChange={this.setChapterData(
                                    "smarterGallery"
                                  )}
                                  value="smarterGallery"
                                />
                              }
                              label="Smarter Gallery"
                            />
                          </Grid>
                        )}
                        {categories.abbSmarterMobility && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={abbSmarterMobility}
                                  onChange={this.setChapterData(
                                    "abbSmarterMobility"
                                  )}
                                  value="abbSmarterMobility"
                                />
                              }
                              label="ABB Smarter Mobility"
                            />
                          </Grid>
                        )}
                        {categories.lbInnovations && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={lbInnovations}
                                  onChange={this.setChapterData(
                                    "lbInnovations"
                                  )}
                                  value="lbInnovations"
                                />
                              }
                              label="Neuheiten der L&B"
                            />
                          </Grid>
                        )}
                        {categories.energiemonitoring && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={energieMonitoring}
                                  onChange={this.setChapterData(
                                    "energieMonitoring"
                                  )}
                                  value="energieMonitoring"
                                />
                              }
                              label="Energiemonitoring"
                            />
                          </Grid>
                        )}
                        {isLoggedin && categories.jahresgespraech_2023 && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={jahresgespraech_2023}
                                  onChange={this.setChapterData(
                                    "jahresgespraech_2023"
                                  )}
                                  value="jahresgespraech_2023"
                                />
                              }
                              label="Jahresgespräch 2023"
                            />
                          </Grid>
                        )}
                        {categories.moderneElektroinstallation && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={moderneElektroinstallation}
                                  onChange={this.setChapterData(
                                    "moderneElektroinstallation"
                                  )}
                                  value="moderneElektroinstallation"
                                />
                              }
                              label="Moderne Elektroinstallation"
                            />
                          </Grid>
                        )}
                        {categories.usv && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={usv}
                                  onChange={this.setChapterData("usv")}
                                  value="usv"
                                />
                              }
                              label="Unterbrechungsfreie Stromversorgung (USV)"
                            />
                          </Grid>
                        )}
                        {isLoggedin && categories.jahresgespraech_2024 && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={jahresgespraech_2024}
                                  onChange={this.setChapterData(
                                    "jahresgespraech_2024"
                                  )}
                                  value="jahresgespraech_2024"
                                />
                              }
                              label="Jahresgespräch 2024"
                            />
                          </Grid>
                        )}
                        {categories.flexline && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={flexline}
                                  onChange={this.setChapterData("flexline")}
                                  value="flexline"
                                />
                              }
                              label={
                                <>
                                  FlexLine<sup>®</sup>
                                </>
                              }
                            />
                          </Grid>
                        )}
                        {categories.neuheiten2024 && (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={neuheiten2024}
                                  onChange={this.setChapterData(
                                    "neuheiten2024"
                                  )}
                                  value="neuheiten2024"
                                />
                              }
                              label="Neuheiten 2024"
                            />
                          </Grid>
                        )}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                </div>
              )}
              {step === 1 && (
                <>
                  <Box ml={2} mt={2}>
                    <Typography pt={5} className={classes.instructions}>
                      Wann sehen wir uns wieder?
                    </Typography>
                  </Box>
                  {
                    <div className={classes.formControl}>
                      <Grid container spacing={4} item>
                        <Grid item xs={4}>
                          <DatePicker
                            inputVariant="outlined"
                            label="Datum"
                            id="nextAppointment"
                            value={nextAppointment}
                            onChange={this.handleDateChange}
                            cancelLabel="Abbrechen"
                            format="dd.MM.yyyy"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            onChange={this.handleFieldChange}
                            id="nextAppointmentPlace"
                            value={nextAppointmentPlace}
                            fullWidth
                            label="Ort"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  }
                  <Box ml={2} mt={2} mb={4}>
                    <Typography
                      className={classes.instructions}
                      style={{ marginBottom: "8px" }}
                    >
                      Möchten Sie weiterhin informiert bleiben?
                    </Typography>
                    <form
                      class="form"
                      target="_blank"
                      name="tx_bjenewsletter_newsletterregistration"
                      action="https://www.busch-jaeger.de/newsletter?tx_bjenewsletter_newsletterregistration%5Baction%5D=new&amp;tx_bjenewsletter_newsletterregistration%5Bcontroller%5D=Newsletter"
                      method="POST"
                    >
                      <Box display="none">
                        <input
                          id="newsletter-firstname"
                          value={this.props.customerData.firstname}
                          type="text"
                          name="tx_bjenewsletter_newsletterregistration[newNewsletter][firstName]"
                        />
                        <input
                          id="newsletter-lastname"
                          value={this.props.customerData.lastname}
                          type="text"
                          name="tx_bjenewsletter_newsletterregistration[newNewsletter][lastName]"
                        />
                        <input
                          id="newsletter-email"
                          value={this.props.customerData.email}
                          type="email"
                          name="tx_bjenewsletter_newsletterregistration[newNewsletter][emailAddress]"
                        />
                        <input
                          type="hidden"
                          name="tx_bjenewsletter_newsletterregistration[__referrer][@extension]"
                          value="Sitepackage"
                        />
                        <input
                          type="hidden"
                          name="tx_bjenewsletter_newsletterregistration[__referrer][@controller]"
                          value="Page"
                        />
                        <input
                          type="hidden"
                          name="tx_bjenewsletter_newsletterregistration[__referrer][@action]"
                          value="main"
                        />
                        <input
                          type="hidden"
                          name="tx_bjenewsletter_newsletterregistration[__referrer][@request]"
                          value='{"@extension":"Sitepackage","@controller":"Page","@action":"main"}d99f703e98aa77390bff949e95229a0f9fe8da81'
                        />
                        <input
                          type="hidden"
                          name="tx_bjenewsletter_newsletterregistration[__trustedProperties]"
                          value='{"newNewsletter":{"firstName":1,"lastName":1,"emailAddress":1}}2e8422061e71d5e0d87ccc1e6143266fc452a0e2'
                        />
                      </Box>
                      <Button type="submit" variant="contained" target="_blank">
                        zum Newsletter anmelden
                      </Button>
                    </form>
                  </Box>
                </>
              )}
              {step === 2 && (
                <div className={classes.centerContent}>
                  <img
                    src="../../../assets/shared/coffee-empty.webp"
                    width="250"
                    height="250"
                  />
                  <Box sx={{ marginBottom: 3 }}>
                    <Typography variant="h5" component="h2" className={``}>
                      Fast fertig!
                    </Typography>
                    <Typography variant="p" color="textSecondary">
                      Alles erfasst? Nichts vergessen?
                    </Typography>
                  </Box>
                </div>
              )}
            </div>
            <div>
              {/* zurueck/weiter toolbar */}
              <Box display="flex" justifyContent="space-between">
                {step !== 0 ? (
                  <Button
                    variant="outlined"
                    disabled={step === 0}
                    onClick={this.handleBack}
                  >
                    Zurück
                  </Button>
                ) : (
                  <div></div>
                )}
                {step === steps.length - 1 && (
                  <div>
                    <Online
                      polling={{
                        url: `${process.env.PREACT_APP_PLAYBOOKAPI}ping`,
                        interval: 60000,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.sendData}
                      >
                        Beratung beenden
                      </Button>
                    </Online>
                    <Offline
                      polling={{
                        url: `${process.env.PREACT_APP_PLAYBOOKAPI}ping`,
                        interval: 60000,
                      }}
                    >
                      <Button variant="contained" color="primary" disabled>
                        Beratung beenden
                      </Button>
                    </Offline>
                  </div>
                )}
                {step !== steps.length - 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                  >
                    Weiter
                  </Button>
                )}
              </Box>
            </div>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const categories = getCategoriesData(state).categories;
  const setup = getSetupData(state);
  const settings = getSettingsData(state);
  const consulting = getConsultingData(state);
  const freeathome = getFreeAtHomeData(state);
  const missiontozero = getMissionToZeroData(state);
  const welcome = getWelcomeData(state);
  const welcomeip = getWelcomeIPData(state);
  const knx = getKnxData(state);
  const comfortline = getComfortLineData(state);
  const installationsgeraete = getInstallationsGeraeteData(state);
  const beruehrungslosesschalten = getBeruehrungslosesSchaltenData(state);
  const cwa = getCwaData(state);
  const jahresgespraech_2021 = getJahresgespraech2021Data(state);
  const jahresgespraech_2022 = getJahresgespraech2022Data(state);
  const serviceandtools = getServiceAndToolsData(state);
  const buschflex = getBuschflexData(state);
  const uk600ak600 = getUK600AK600Data(state);
  const abbSmarterMobility = getAbbSmarterMobilityData(state);
  const lbInnovations = getLbInnovationsData(state);
  const energieMonitoring = getEnergieMonitoringData(state);
  const jahresgespraech_2023 = getJahresgespraech2023Data(state);
  const moderneElektroinstallation = getModerneElektroinstallationData(state);
  const jahresgespraech_2024 = getJahresgespraech2024Data(state);
  const usv = getUSVData(state);
  const flexline = getFlexLineData(state);
  const neuheiten2024 = getNeuheiten2024Data(state);
  const jahresgespraech_2025 = getJahresgespraech2025Data(state);

  const data = {
    setup,
    settings,
    consulting,
    freeathome,
    missiontozero,
    welcome,
    welcomeip,
    knx,
    comfortline,
    installationsgeraete,
    beruehrungslosesschalten,
    cwa,
    jahresgespraech_2021,
    jahresgespraech_2022,
    serviceandtools,
    buschflex,
    uk600ak600,
    abbSmarterMobility,
    lbInnovations,
    energieMonitoring,
    jahresgespraech_2023,
    moderneElektroinstallation,
    usv,
    jahresgespraech_2024,
    flexline,
    neuheiten2024,
    jahresgespraech_2025,
  };

  const isLoggedin = isLoggedIn(state);

  return {
    data,
    nextAppointment: consulting.nextAppointment,
    nextAppointmentPlace: consulting.nextAppointmentPlace,
    consultingStarted: consulting.consultingStarted,
    categories,
    isLoggedin,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setActiveChapters(chapters) {
    dispatch(setActiveChapters(chapters));
  },
  setNextAppointment(date) {
    dispatch(setNextAppointment(date));
  },
  setSetupField(valueSet) {
    switch (valueSet.id) {
      case "nextAppointmentPlace":
        {
          dispatch(setNextAppointmentPlace(valueSet.value));
        }
        break;
    }
  },
  finishConsulting() {
    dispatch(setConsultingHasEnded());
    dispatch(setConsultingTimeTrackingStatus(false));
    dispatch(resetConsulting());
  },
  resetConsulting() {
    dispatch(resetConsulting());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultPage);
